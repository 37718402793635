import { Observable } from 'rxjs';
// import AuthService from '@services/Auth/AuthService';
import { BreadcrumbMappings } from './BreadcrumbMappings';
import store from 'store2';

class MenuService {
    // if menu is comming from backend please make a request and return promise otherwise return menu array
    menu = [
        {
            group: 'Regulatory Monitoring',
            items: [
                {
                    url: 'search',
                    title: 'Search',
                    roles: ['ALL']
                },
                {
                    url: 'alerts',
                    title: 'Alerts',
                    roles: ['ALL']
                },
                {
                    url: 'global-content',
                    title: 'Global Content',
                    roles: ['ALL']
                }
            ]
        },
        {
            group: 'Analysis',
            items: [
                {
                    url: 'regulatory-analysis',
                    title: 'Regulatory Analysis',
                    roles: ['ALL']
                },
                {
                    url: 'controls-analysis',
                    title: 'Controls Analysis',
                    icon: 'eye',
                    roles: ['ALL']
                },
                {
                    url: 'risk-heatmaps',
                    title: 'Risk Heatmaps',
                    roles: ['ALL']
                },
                {
                    url: 'regulation-profiler',
                    title: 'Regulation Profiler',
                    roles: ['ALL']
                }
            ]
        }
    ]


    getMenuFromBackend() {
        // Make http request to get a menu list, prepare menu structure and return as observable
        return new Observable(subscriber => {
            subscriber.next('menu_from_the_backend');
        });
    }

    getMenuArray() {
        return new Observable(subscriber => {
            subscriber.next(this.menu);
        });
    }

    getMappedBreadcrumb() {
        return BreadcrumbMappings;
    }

    blockAccess(items) {
        let roles = [];

        items.forEach(item => {
            if (!item.roles) return;
            roles = [...roles, ...item.roles];
        });
        return this.checkRoles(roles);
    }

    checkAccess(menu, route) {
        let roles = [];

        this.menu.forEach(block => {
            block.items.forEach(item => {
                if (item.url === route) roles = item.roles;
            });
        });

        if (!roles.length) return false;

        let hasAccess = false;

        if (this.checkRoles(roles)) hasAccess = true;

        return hasAccess;
    }

    checkRoles(roles) {
        if (!roles) return false;

        const userRoles = store.get('roles');
        if (!userRoles) return false;

        let hasAccess = false;

        if (roles.includes('ALL')) hasAccess = true;
        else {
            roles.forEach(role => {
                if (userRoles.includes(role)) hasAccess = true;
            });
        }
        return hasAccess;
    }

    defineHomepage() {
        let homepage = null;

        this.menu.every(block => {
            block.items.every(item => {
                if (this.checkRoles(item.roles)) {
                    if (item.url !== 'documents') homepage = '/' + item.url;
                    return false;
                }
                return true;
            });
            if (homepage) return false;
            return true;
        });

        if (!homepage) return '/documents';
        return homepage;
    }
}
export default new MenuService();