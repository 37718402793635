import React from 'react';
import AuthService from '@services/Auth/AuthService';
import GlobalSettings from './GlobalSettings';
import TaxonomyMapsSelector from './TaxonomyMapsSelector/TaxonomyMapsSelector';
import EmailSupport from '@components/EmailSupport/EmailSupport';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.onLogout = this.onLogout.bind(this);
    }

    onLogout(noRedirection) {
        return AuthService.logoutUser(noRedirection);
    }

    render() {
        return (
            <header className="app-header border-bottom">
                <div className="row">
                    <div className="col">
                        <div className="app-header-container">
                            <a href="https://pwc.corlytics.com" className="page-logo">
                                <img src="/img/logo.png" alt="Corlytics" />
                            </a>
                            <EmailSupport />
                            <TaxonomyMapsSelector />
                            <GlobalSettings logout={this.onLogout} />
                        </div>
                        {/* <span className="app-subtitle-divider"></span>
                        <span className="app-subtitle">Sample subtitle</span> */}
                    </div>
                    {/* <div className="col text-right">
                    </div> */}
                </div>
            </header>
        )
    }
}
export default Header;