import { Observable } from 'rxjs';
import Axios from 'axios-observable';

const Domain = process.env.REACT_APP_MAIN_API_SERVER;

class RFclients {
    get() {
        return new Observable(subscriber => {
            subscriber.next([]);
            subscriber.complete();
            // Axios.post(Domain + '/RiskFusion/GetClientNames/', {}).subscribe(resp => {
            //     subscriber.next(resp.data.clients);
            //     subscriber.complete();
            // });
        })
    }
}
export default new RFclients();