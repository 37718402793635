import { Observable } from 'rxjs';
import Axios from 'axios-observable';

const Domain = process.env.REACT_APP_MAIN_API_SERVER;

class ServiceLines {
    mapData(data) {
        return data.map(item => {
            if (item.children && item.children.length) {
                item.children = this.mapData(item.children);
            }
            return { ...item, title: item.name, value: item.id };
        })
    }

    get() {
        return new Observable(subscriber => {
            Axios.get(Domain + '/mapped-taxonomy/Service Lines?flat=false').subscribe(resp => {
                const data = this.mapData(resp.data.children);
                subscriber.next(data);
                subscriber.complete();
            });
        })
    }
}
export default new ServiceLines();