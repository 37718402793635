import axios from 'axios';
import Cookies from 'js-cookie';
import store from 'store2';
import { notification } from 'antd';
import { Subject } from 'rxjs';
import ApiService from '@services/Api/ApiService';
import ROLES from '@base/user-roles';

import { loginRequest, msalConfig } from '../../MsalConfig/authConfig';

class AuthService {
    pendingRequests = 0;
    finishedRequests = 0;

    msalInstance = null;
    msalAccounts = null;

    userInitialDone = false;
    userAccessInitial = new Subject();

    requestSending = false;
    interceprotLoaded = false;

    roles = null;
    session = null;

    userData = null;

    constructor() {
        // this.initAxiosInterceptor();

        this.userLoggedIn = !!this.getMsalToken();
    }

    initService() {
        this.initAxiosInterceptor();
    }


    // MSAL Single Sign On
    setMsalData(instance, accounts) {
        this.msalInstance = instance;
        this.msalAccounts = accounts;
    }

    getMsalInstance() {
        return this.msalInstance;
    }

    getMsalAccounts() {
        return this.msalAccounts;
    }

    sendSilentTokenRequest() {
        const accounts = this.getMsalAccounts();
        if (!accounts || !accounts.length) return;

        const instance = this.getMsalInstance();

        return instance.acquireTokenSilent({
            scopes: [msalConfig.auth.clientId],
            account: accounts[0]
        });
    }

    msalLogoutUser() {
        const instance = this.getMsalInstance();
        const accounts = this.getMsalAccounts();
        instance.logoutRedirect({
            account: accounts[0],
            postLogoutRedirectUri: '/'
        });
    }

    msalLoginUserRedirect() {
        const instance = this.getMsalInstance();
        instance.loginRedirect(loginRequest);
    }

    setMsalToken(token) {
        Cookies.set('MsalAuthToken', token, { expires: 7 });
        this.setNewAccessToken(token);
    }

    checkAccess(role) {
        if (!this.roles) {
            this.roles = store.get('roles');
        }
        if (!this.roles) return null;
        return this.roles.includes(ROLES[role]);
    }

    getClientName() {
        if (!this.session) this.session = store.get('CorlyticsSession');

        if (this.session.client_name) {
            return this.session.client_name;
        }
        return null;
    }

    setNewAccessToken(token) {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        this.getUserInitialSettings();
    }

    getMsalToken() {
        return Cookies.get('MsalAuthToken');
    }

    isMsalUserLoggedIn() {
        return this.sendSilentTokenRequest();
    }

    isLoggedIn() {
        return this.msalAccounts && this.msalAccounts.length > 0;
    }
    // Enf of MSAL methods

    getUserInitialSettings() {
        const baseUrl = process.env.REACT_APP_API_SERVER;
        return axios.get(baseUrl + '/api/v1/get-self/');
    }

    setUserInitialSettings(data) {
        if (this.userInitialDone) return;

        store.set('CorlyticsSession', data);
        store.set('roles', data.roles);
        console.log("Welcome " + this.getUsername())
        console.log("Application Version " + data.version);

        this.userInitialDone = true;
        this.userAccessInitial.next(true);
        return data;
    }

    getUserRoles() {
        const session = store.get('CorlyticsSession');
        if (session && session.roles) return session.roles;
        return [];
    }

    initAxiosInterceptor() {
        if (this.interceprotLoaded) return;
        this.interceprotLoaded = true;

        const getAccessToken = () => {
            const accounts = this.getMsalAccounts();
            if (!accounts || !accounts.length) return Promise.reject();

            const instance = this.getMsalInstance();

            return instance.acquireTokenSilent({
                scopes: [msalConfig.auth.clientId],
                // scopes: protectedResources.apiHello.scopes,
                account: accounts[0]
            });
        }


        // Request interceptor
        axios.interceptors.request.use(
            async (request) => {
                request.headers['Pragma'] = 'no-cache';

                try {
                    const token = await getAccessToken();
                    if (token) request.headers['Authorization'] = 'Bearer ' + token.accessToken;

                    // Start spinner
                    if ((request.hasOwnProperty('loader') && request.loader === true) || !request.hasOwnProperty('loader')) {
                        this.toggleSpinner(true);
                        this.pendingRequests++;
                    }
                    return request;
                }
                catch (error) {
                    // store.clearAll();
                    // this.msalLogoutUser();

                    // if (error && error.name) {
                    //     if (error.name === 'InteractionRequiredAuthError') {
                    //         this.msalService.acquireTokenRedirect(this.requesttoken);
                    //     }
                    // }

                    if (this.isLoggedIn()) this.msalLogoutUser();

                }
            }
        );

        // Response interceptor
        axios.interceptors.response.use(response => {
            if ((response.config.hasOwnProperty('loader') && response.config.loader === true) || !response.config.hasOwnProperty('loader')) {
                this.finishedRequests++;
            }

            if (this.pendingRequests <= this.finishedRequests) {
                this.toggleSpinner(false); // Stop spinner
                this.resetRequests();
            }
            return response;
        }, error => {
            if (error.response?.config) {
                if ((error.response.config.hasOwnProperty('loader') && error.response.config.loader === true) ||
                    !error.response.config.hasOwnProperty('loader')
                ) {
                    this.finishedRequests++;
                }
            }
            else this.finishedRequests++;

            if (this.pendingRequests === this.finishedRequests) {
                this.toggleSpinner(false); // Stop spinner
                this.resetRequests();
            }

            if (error && error.toString() === 'Cancel') return;

            if (!error || !error.response) {
                notification.error({
                    message: 'Error occurred while loading'
                    // description: 'Error occurred while loading'
                });
                return Promise.reject(error);
            }

            if (error.response.status === 404 && error.response.data && error.response.data.message) {
                notification.error({
                    message: error.response.data.message
                    // description: error.response.data.message
                });
            }

            if (error.response.status === 400 && error.response.data && error.response.data.message) {
                notification.error({
                    message: error.response.data.message,
                    // description: error.response.data.message
                });
            }

            if (error.response.status === 401 || error.response.data.message === '401 Unauthorized') {
                const msg = error.response.data && error.response.data.message ? error.response.data.message : 'Unauthorized';
                notification.error({
                    message: msg
                });

                setTimeout(() => {
                    this.msalLogoutUser();
                }, 3000);
            }
            return Promise.reject(error);
        });
    }

    getUsername() {
        const session = store.get('CorlyticsSession');
        if (!session || !session.user) return '';
        return session.user.username;
    }









    toggleSpinner(loading) {
        document.body.classList[loading ? 'add' : 'remove']('loading');
    }






    resetRequests() {
        this.pendingRequests = 0;
        this.finishedRequests = 0;
    }

    publicUrl() {
        const url = process.env.PUBLIC_URL === '/' ? '' : process.env.PUBLIC_URL;
        return window.location.origin + url;
    }

    logoutUser() {
        Cookies.remove('rigtoken');
        store.clearAll();
        window.location.href = this.publicUrl() + '/login';
        return null;
    }

    loginUser(username, password) {
        return ApiService.loginUser(username, password);
    }

    setLoggedUserDetails(data, url) {
        Cookies.set('rigtoken', data.auth_token, { expires: 1 });
        store.set('username', data.message.replace('Welcome ', ''));
        store.set('dateFmt', data.dateFmt);
        store.set('currency', data.currency);
        this.setUserRoles(data.roles);
        this.userLoggedIn = true;
        window.location.href = this.publicUrl() + (url ? url : '/');
    }

    setUserRoles(roles) {
        store.set('roles', roles);
    }

    isUserLoggedIn() {
        return this.userLoggedIn;
    }

    isAdmin() {
        const roles = this.getUserRoles();
        return roles.includes('ADMIN');
    }
}

export default new AuthService();