export const BreadcrumbMappings = [
    {
        route: '/search',
        items: [
            { url: '', name: 'Search' }
        ]
    },
    {
        route: '/alerts',
        items: [
            { url: '', name: 'Alerts' }
        ]
    },
    {
        route: '/global-content',
        items: [
            { url: '', name: 'Global Content' }
        ]
    },
    {
        route: '/regulatory-analysis',
        items: [
            { url: '', name: 'Regulatory Analysis' }
        ]
    },
    {
        route: '/controls-analysis',
        items: [
            { url: '', name: 'Controls Analysis' }
        ]
    },
    {
        route: '/risk-heatmaps',
        items: [
            { url: '', name: 'Risk Heatmaps' }
        ]
    },
    {
        route: '/regulation-profiler',
        items: [
            { url: '', name: 'Regulation Profiler' }
        ]
    },
    {
        route: '/manage-saved-searches',
        items: [
            { url: '', name: 'Manage Saved Searches' }
        ]
    },
    {
        route: '/fines-list',
        items: [
            { url: '', name: 'Enforcements List' }
        ]
    },
    {
        route: '/latest/detail',
        items: [
            { url: '', name: 'Document Details' }
        ]
    },
    {
        route: '/licenses',
        items: [
            { url: '', name: 'Licenses' }
        ]
    }
]