import { LogLevel } from "@azure/msal-browser";
const queryString = require('query-string');
/**
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1A_TRUSTFRAMEWORKEXTENSIONS_SEAMLESSMIGRATION"
    },
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_AUTHORITY
        }
    },
    authorityDomain: process.env.REACT_APP_AUTHORITY
}
/**
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URL,
        postLogoutRedirectUri: process.env.REACT_APP_AUTH_REDIRECT_URL,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        // console.debug(message);
                        return;
                    case LogLevel.Warning:
                        // console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

/**
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [],
    state: JSON.stringify({
        url: window.location.pathname,
        params: queryString.parse(window.location.search)
    })
};
export const protectedResources = {
    graphMe: {
        endpoint: "https://graph.microsoft.com/v1.0/me",
        scopes: ["User.Read"],
    },
    apiHello: {
        endpoint: process.env.REACT_APP_AUTH_REDIRECT_URL,
        scopes: ["openid"], // e.g. api://xxxxxx/access_as_user
    },
}