import React from 'react';
import { Subscription } from 'rxjs';
import { Link, withRouter } from 'react-router-dom';
import MenuService from '@services/Menu/MenuService';

class Breadcrumb extends React.Component {
    constructor(props) {
        super(props);
        this.subscriptions = new Subscription();

        this.state = {
            breadcrumbs: [],
            activeBreadcrumg: []
        }
    }

    componentDidMount() {
        this.getBreadcrumb();
    }

    componentWillUnmount() {
        this.subscriptions.unsubscribe();
    }

    getBreadcrumb() {
        this.setState(state => {
            state.breadcrumbs = MenuService.getMappedBreadcrumb();
            return state;
        });
    }

    getActiveBreadcrumbRoute(path) {
        if (process.env.PUBLIC_URL && process.env.PUBLIC_URL.length > 1) {
            path = path.replace(process.env.PUBLIC_URL, '');
        }
        const pathArray = path.split('/');
        const activeRoute = this.state.breadcrumbs.filter(item => {
            const itemsArray = item.route.split('/');

            if (pathArray.length !== itemsArray.length) return null;
            else {
                let isCorrectRoute = true;
                itemsArray.forEach((route, i) => {
                    if (route !== ':id' && route !== ':name' && pathArray[i] !== route) isCorrectRoute = false;
                });
                return isCorrectRoute;
            }
        });
        return activeRoute.length ? activeRoute[0].items : null;
    }

    getActiveBreadcrumb(path) {
        if (!this.state.breadcrumbs || path === '/') return null;
        else {
            const activeRoute = this.getActiveBreadcrumbRoute(path);
            if (!activeRoute) return null;

            return activeRoute.map((item, i) => {
                const last = i === activeRoute.length - 1;

                return <li key={i}>
                    <i className="fa fa-angle-right"></i>
                    {!last && <Link to={'/' + item.url}>{item.name}</Link>}
                    {last && item.name}
                </li>;
            });
        }

    }

    render() {
        return (
            <div className="app-breadcrumb">
                <ul className="list-style-none">
                    <li><Link to="/">Home</Link></li>
                    {this.getActiveBreadcrumb(window.location.pathname)}
                </ul>
            </div>
        )
    }
}
export default withRouter(Breadcrumb);