const ROLES = {
    adminAccess: 'ADMIN',
    legalAnalystWorkflow: 'COR-WORKFLOW',
    relevanceAccess: 'COR-RELEVANCE',
    riskScores: 'RISK-SCORES',
    textAnalyticsAccess: 'COR-TEXT',
    complianceTracker: 'TRACKER',
    workflowAdmin: 'COR-WFADMIN',
    workflowSuper: 'WORKFLOW-SUPER',
    workflowUser: 'WORKFLOW-USER',
    RegulatoryInbox: 'REG-INBOX',
    ServiceLineView: 'SL-VIEW+SEARCH',
    ServiceLineEditor: 'SL-EDITOR',
    ServiceLineAlerts: 'SL-ALERTS',
    RegCategoryView: 'RC-VIEW+SEARCH',
    RegCategoryEditor: 'RC-EDITOR',
    RegCategoryAlerts: 'RC-ALERTS',
    VisualQuery: 'VISUAL-QUERY',
    ClientUsageReport: 'CLIENT-USE-REP',
    modelMaintenance: 'MODEL-MAINT',
    modelProduction: 'MODEL-PROD',
    regulationTags: 'REG-TAG',
    riskAssessment: 'REG-ASSESS',
    regMonitoring: 'REGMONITORING',
    alerting: 'ALERTING',
    riskApps: 'RISK-APPS',
    regVersions: 'REG-VERSION',
    autoTranslate: 'AUTO-TRANSLATE',
    ThemeView: 'THEMES-VIEW',
    themesEdit: 'THEMES-EDIT',
    DashboardAdmin: 'DASHBOARD-ADMIN',
    watchlistAccess: 'REG-WATCH',
    regPublish: 'REG-PUBLISH',
    regMakePublic: 'REG-MAKE-PUBLIC',
    regPublishToStaging: 'REG-PUB-STAGING',
    regPublishToProduction: 'REG-PUB-PROD',
    workflowClientAdmin: 'WF-CLIENTADMIN',
    routingRuleSelfServeSuper: 'SELFSERVE-SUPER',
    routingRuleSelfServeUser: 'SELFSERVE-USER',
    noticeQueues: 'NOTICE-QUEUES',
    clientTaxonomyEdit: 'TAXONOMY-EDIT',
    clientTaxonomy: 'CLIENT-TAXONOMY',
    taxonomyAdmin: 'TAXONOMY-ADMIN',
    finraUser: 'FINRA-USER',
    finraAdmin: 'FINRA-ADMIN',
    finraVis: 'FINRA-VIS',
    esgDash: 'ESG-DASH',
    sanctionsMonitor: 'SANCTIONS-MNTR',
    pushApiAccess: 'PUSH-API-DASH',
    metaDataAccess: 'META-DATA',
    documentExport: 'DOC-EXPORT',
    summaryAccess: 'SUMMARIES',
    regUpdateFlag: 'HS-REG-FLAG',
    markup: 'MARKUP',
    markupValidator: 'MARKUP-VALIDATE',
    procedureAdmin: 'PROCEDURE-ADMIN'
}
export default ROLES;