import { Observable } from 'rxjs';
import Axios from 'axios-observable';

const Domain = process.env.REACT_APP_MAIN_API_SERVER;

class DocumentTypes {
    get() {
        return new Observable(subscriber => {
            Axios.get(Domain + '/document-categories/').subscribe(resp => {
                const tree = this.prepareTree(resp.data.categories);
                subscriber.next(tree);
                subscriber.complete();
            });
        })
    }

    prepareTree(data) {
        const structure = {
            'Regulatory Developments': ['Consultation', 'Guidance', 'Policy', 'Regulation', 'Standard', 'Statement'],
            'Corporate Communications': ['Press Release', 'Business Plan', 'Speech', 'Report', 'Warning', 'Parliamentary Commentary'],
            'Enforcements': ['Enforcement', 'Industry Letter']
        };

        const tree = [];

        for (let i in structure) {
            const item = {
                label: i,
                value: 'parent-' + i.replace(' ', '-').toLowerCase(),
                children: data.filter(el => structure[i].includes(el.label))
            };
            tree.push(item);
        }
        return tree;
    }
}
export default new DocumentTypes();