import React, { useState } from 'react';

const { Button, Modal, ModalHeader, ModalBody, ModalFooter } = window['appkit-react'];

export default function EmailSupport(props) {
    const [modal, setModal] = useState(false);

    return <>
        <Button
            size="lg"
            kind="secondary"
            onClick={() => setModal(true)}
            style={{ position: 'absolute', left: '110px', top: '10px' }}
        >Contact Us</Button>
        {modal &&
            <Modal
                visible={modal}
                onCancel={() => setModal(false)}
                size="lg"
                className="email-support-modal"
            >
                <ModalHeader><h5>Contact Us</h5></ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col">
                            <a href="mailto:de_eufinreg@pwc.com" className="no-decoration">
                                <Button size="lg" kind="secondary">Email Us</Button>
                            </a>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col" style={{ fontSize: '14px' }}>
                            To access detailed resources from PwC Legal please visit our <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.pwclegal.de/en/services/financial-services/pwc-legals-financial-institutions-regulatory-europe-team/pwc-legals-eu-regulatory-compliance-operations-risk-and-engagement-eu-regcore-centre/">EU RegCORE.</a>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="d-block pt-1 px-0">
                    <div className="row">
                        <div className="col text-right">
                            <Button size="lg" kind="primary" onClick={() => setModal(false)}>Close</Button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        }
    </>
}