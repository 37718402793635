import Axios from 'axios-observable';
const Domain = process.env.REACT_APP_MAIN_API_SERVER;

class MainApiService {
    getHeatmapsData(showLoader) {
        return Axios.post(Domain + '/RiskFusion/HeatmapFilterData/', {}, { loader: showLoader });
    }

    getHeatmapsRegCategories(filters, showLoader) {
        return Axios.post(Domain + '/RiskFusion/HeatmapCategoryData/', { filters }, { loader: showLoader });
    }

    getHeatmapsServiceLines(filters, showLoader) {
        return Axios.post(Domain + '/RiskFusion/HeatmapServiceLineData/', { filters }, { loader: showLoader });
    }

    RegAnalysisRemoveBookmark(id, showLoader) {
        return Axios.post(Domain + '/RiskFusion/RemoveBookmark/', { id }, { loader: showLoader });
    }

    RegAnalysisAddBookmark(id, showLoader) {
        return Axios.post(Domain + '/RiskFusion/AddBookmark/', { id }, { loader: showLoader });
    }

    RegAnalysisGetSearchCriteria(showLoader) {
        return Axios.post(Domain + '/RiskFusion/GetSearchCriteria/', {}, { loader: showLoader });
    }

    RegAnalysisSearchEnforcements(data, showLoader) {
        return Axios.post(Domain + '/RiskFusion/SearchEnforcements/', data, { loader: showLoader });
    }

    RegAnalysisGetBookmarks(data, showLoader) {
        return Axios.post(Domain + '/RiskFusion/GetBookmarks/', data, { loader: showLoader });
    }

    RegAnalysisGetEnforcementsExport(data, showLoader) {
        return Axios.post(Domain + '/RiskFusion/GetEnforcementsForExport/', data, { loader: showLoader });
    }

    RiskFusionGetAutocompleteData(url, data, showLoader) {
        return Axios.post(Domain + url, data, { loader: showLoader });
    }

    GetSummary(id, showLoader) {
        return Axios.get(Domain + '/pageitems/' + id + '/summary/', { loader: showLoader });
    }

    GetFyi(id, data, showLoader) {
        return Axios.post(Domain + '/pageitems/' + id + '/fyi/', data, { loader: showLoader });
    }

    RiskFusionSaveSearch(data, showLoader) {
        return Axios.post(Domain + '/RiskFusion/SaveSearch/', data, { loader: showLoader });
    }

    GetUserSearches(showLoader) {
        return Axios.post(Domain + '/RiskFusion/GetUserSearches/', {}, { loader: showLoader });
    }

    GetSharedSearches(showLoader) {
        return Axios.post(Domain + '/RiskFusion/GetSharedSearches/', {}, { loader: showLoader });
    }

    GetUserSearch(id, showLoader) {
        return Axios.post(Domain + '/RiskFusion/LoadSearch/', { Id: id }, { loader: showLoader });
    }

    UpdateUserSearch(data, showLoader) {
        return Axios.post(Domain + '/RiskFusion/UpdateSearch/', data, { loader: showLoader });
    }

    GetControlsExplorerData(showLoader) {
        return Axios.post(Domain + '/RiskFusion/ControlsExplorerData/', {}, { loader: showLoader });
    }

    GetRegulationProfilerData(showLoader) {
        return Axios.post(Domain + '/RiskFusion/ProvisionsProfilerData/', {}, { loader: showLoader });
    }

    GetRegulationFilteredResults(data, showLoader) {
        return Axios.post(Domain + '/RiskFusion/FilteredProvisionsProfilerResults/', data, { loader: showLoader });
    }

    GetRegulationProfilerEnforcements(data, showLoader) {
        return Axios.post(Domain + '/RiskFusion/ProvisionsProfilerEnforcements/', data, { loader: showLoader });
    }

    GetGlobalContentRegions(code, showLoader) {
        return Axios.get(Domain + '/dashboard/region/' + code, { loader: showLoader });
    }

    SetRegulationUpdateFlag(id, data, showLoader) {
        return Axios.put(Domain + '/pageitems/' + id + '/regulation-update/', data, { loader: showLoader });
    }

    GetDocumentSummary(id, showLoader) {
        return Axios.get(Domain + '/pageitems/' + id + '/summary/', { loader: showLoader });
    }

    GetAdvancedSearchCount(data, showLoader) {
        return Axios.post(Domain + '/combined/advanced-search/count/', data, { loader: showLoader });
    }

    GetAdvancedSearchResults(data, showLoader) {
        return Axios.post(Domain + '/combined/advanced-search/', data, { loader: showLoader });
    }

    AddDocumentBookmark(data, showLoader) {
        return Axios.post(Domain + '/bookmarks/', data, { loader: showLoader });
    }

    RemoveDocumentBookmark(id, showLoader) {
        return Axios.delete(Domain + '/bookmarks/' + id, { loader: showLoader });
    }

    UpdateSavedSearch(id, data, showLoader) {
        return Axios.put(Domain + '/saved-search/' + id, data, { loader: showLoader });
    }

    CreateSavedSearch(data, showLoader) {
        return Axios.post(Domain + '/saved-search/', data, { loader: showLoader });
    }

    DeleteSavedSearch(id, showLoader) {
        return Axios.delete(Domain + '/saved-search/' + id, { loader: showLoader });
    }

    DownloadSearchResults(data, showLoader) {
        return Axios.post(Domain + '/combined/advanced-search/download/', data, { loader: showLoader });
    }

    UpdateSearchListIndicator(id, data, showLoader) {
        return Axios.put(Domain + '/pageitems/' + id, data, { loader: showLoader });
    }

    UpdateUserPreferences(data, showLoader) {
        return Axios.post(Domain + '/users/preferences/', data, { loader: showLoader });
    }

    GetAlertRules(showLoader) {
        return Axios.get(Domain + '/alert_rules/', { loader: showLoader });
    }

    DeleteAlertRule(id, showLoader) {
        return Axios.delete(Domain + '/alert_rules/' + id, { loader: showLoader });
    }

    ToggleAlertRule(id, showLoader) {
        return Axios.put(Domain + '/alert_rules/' + id + '/toggle/', {}, { loader: showLoader });
    }

    SaveAlertRule(data, showLoader) {
        return Axios.post(Domain + '/combined/advanced-search/', data, { loader: showLoader });
    }

    GetAlertLogs(id, matched, showLoader) {
        return Axios.get(Domain + '/alert-logs/' + id + '?matched=' + matched, { loader: showLoader });
    }

    GetAlertRuleDetails(id, showLoader) {
        return Axios.get(Domain + '/alert_rules/' + id, { loader: showLoader });
    }

    SaveAlertRuleData(data, id, showLoader) {
        if (id) return Axios.put(Domain + '/alert_rules/' + id, data, { loader: showLoader });
        return Axios.post(Domain + '/alert_rules/', data, { loader: showLoader });
    }

    GetRFcontrols(showLoader) {
        return Axios.post(Domain + '/RiskFusion/GetSearchCriteria/', {}, { loader: showLoader });
    }

    GetHeatmapsRegCategoriesEnforcements(data, showLoader) {
        return Axios.post(Domain + '/RiskFusion/HeatmapCategoryEnforcements/', data, { loader: showLoader });
    }

    GetHeatmapsServiceLinesEnforcements(data, showLoader) {
        return Axios.post(Domain + '/RiskFusion/HeatmapServiceLineEnforcements/', data, { loader: showLoader });
    }

    DownloadFinesList(data, showLoader) {
        return Axios.request({
            method: 'post',
            url: Domain + '/RiskFusion/ExportFines/',
            data: data,
            responseType: 'blob'
        });
    }

    ApplyTaxonomyTags(data, showLoader) {
        return Axios.post('/combined/advanced-search/apply-taxonomy-tags/', data, { loader: showLoader });
    }

    GetDocumentTimeline(id, showLoader) {
        return Axios.get(Domain + '/pageitems/' + id + '/timeline/', { loader: showLoader });
    }

    UpdateServiceLines(data, showLoader) {
        return Axios.post(Domain + '/combined/advanced-search/apply-service-lines/', data, { loader: showLoader });
    }

    GetLinkedDocuments(id, showLoader) {
        return Axios.get(Domain + '/pageitems/' + id + '/linked-documents/', { loader: showLoader });
    }

    GetFineLinks(id, showLoader) {
        return Axios.get(Domain + '/pageitems/' + id + '/links/', { loader: showLoader });
    }

    GetDocumentAttachments(id, showLoader) {
        return Axios.get(Domain + '/attachments/' + id, { loader: showLoader });
    }

    GetDocumentPDFview(id, showLoader) {
        return Axios.get(Domain + '/pageitems/' + id + '/pdf-view/', { loader: showLoader });
    }

    ExportDocumentPDF(id, showLoader) {
        return Axios.get(Domain + '/pageitems/' + id + '/summary/', { loader: showLoader });
    }

    SendListIndicatorStatus(id, data, showLoader) {
        return Axios.put(Domain + '/pageitems/' + id, data, { loader: showLoader });
    }

    CreateNewDocumentLink(id, data, showLoader) {
        return Axios.post(Domain + '/pageitems/' + id + '/links/', data)
    }

    DeleteDocumentLink(pageId, documentId, showLoader) {
        return Axios.delete(Domain + '/pageitems/' + pageId + '/links/' + documentId, { loader: showLoader });
    }

    GetDocumentTags(id, showLoader) {
        return Axios.get(Domain + '/pageitems/' + id + '/tags/', { loader: showLoader });
    }

    GetDocumentTranslation(id, showLoader) {
        return Axios.get(Domain + '/pageitems/' + id + '/translate/', { loader: showLoader });
    }

    GetDocumentAuditLog(id, showLoader) {
        return Axios.get(Domain + '/pageitems/' + id + '/auditlog/', { loader: showLoader });
    }

    GetNoticeAssessments(id, showLoader) {
        return Axios.get(Domain + '/notice-assessments/' + id, { loader: showLoader });
    }

    GetClientControls(showLoader) {
        return Axios.get(Domain + '/client-control/', { loader: showLoader });
    }

    GetNoticeQueue(id, showLoader) {
        return Axios.get(Domain + '/user-notice-queue/' + id, { loader: showLoader });
    }

    SaveNoticeQueue(data, showLoader) {
        return Axios.post(Domain + '/user-notice-queue/', data, { loader: showLoader });
    }

    GetArticleDetails(id, showLoader) {
        return Axios.get(Domain + '/pageitems/' + id, { loader: showLoader });
    }

    PostClickthrough(id, showLoader) {
        return Axios.post(Domain + '/pageitems/' + id + '/clickthrough/', {}, { loader: showLoader });
    }

    DeleteArticle(id, showLoader) {
        return Axios.delete(Domain + '/pageitems/' + id, { loader: showLoader });
    }

    ShareArticle(data, showLoader) {
        return Axios.post(Domain + '/share-notice/', data, { loader: showLoader });
    }

    ExportNoticeDocx(id, showLoader) {
        return Axios.request({
            method: 'get',
            url: Domain + '/RiskFusion/GetNoticeDocx/' + id,
            responseType: 'blob'
        });
    }

    ExportEnforcementDocx(id, showLoader) {
        return Axios.request({
            method: 'get',
            url: Domain + '/RiskFusion/GetEnforcementDocx/' + id,
            responseType: 'blob'
        });
    }

    ExportAsPDF(id, showLoader) {
        return Axios.request({
            method: 'get',
            url: Domain + '/pageitems/' + id + '/pdf/',
            responseType: 'blob'
        });
    }

    SetCurrentTaxonomyView(data, showLoader) {
        return Axios.post(Domain + '/taxonomy/set-active-model/', data, { loader: showLoader });
    }

    GetLicenses(showLoader) {
        return Axios.get(Domain + '/licenses/', { loader: showLoader });
    }
}

export default new MainApiService();