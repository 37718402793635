import { Observable } from 'rxjs';
import Axios from 'axios-observable';

const Domain = process.env.REACT_APP_MAIN_API_SERVER;

class UserGroups {
	get() {
		return new Observable((subscriber) => {
			Axios.get(Domain + '/client-groups/').subscribe((resp) => {
				subscriber.next(resp.data.groups);
				subscriber.complete();
			});
		});
	}
}
export default new UserGroups();
