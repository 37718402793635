import React, { useEffect, useState, useRef } from 'react';
import CacheService from '@services/Cache/CacheService';
import MainApiService from '@services/Api/MainApiService';
import './style.scss';

export default function TaxonomyMapsSelector(props) {
    const subscriptions = useRef([]);
    const taxonomyMapContainer = useRef(null);
    const [maps, setMaps] = useState([]);
    const [activeMap, setActiveMap] = useState(null);
    const [listOpen, setListOpen] = useState(false);

    useEffect(() => {
        getTaxonomyMaps();
        addClickOutsideEvent();

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            subscriptions.current.forEach(sub => sub.unsubscribe());
            removeClickOutsideEvent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function addClickOutsideEvent() {
        document.addEventListener('mousedown', clickOutsideEvent);
    }

    function removeClickOutsideEvent() {
        document.removeEventListener('mousedown', clickOutsideEvent);
    }

    function clickOutsideEvent(e) {
        if (taxonomyMapContainer && taxonomyMapContainer.current && !taxonomyMapContainer.current.contains(e.target)) {
            setListOpen(false);
        }
    }

    function getTaxonomyMaps() {
        subscriptions.current.push(
            CacheService.getTaxonomyMaps().subscribe(resp => {
                setMaps(resp);
                resp.forEach(map => {
                    if (map.isDefault) setActiveMap(map);
                });
            })
        );
    }

    function onItemClick(item) {
        setActiveMap(item);
        setListOpen(false);

        subscriptions.current.push(
            MainApiService.SetCurrentTaxonomyView({ models: [item.modelId] }).subscribe(resp => {
                window.location.reload();
            })
        );
    }

    const mapsListItems = maps.map(map => {
        return <div
            className="taxonomy-map-selector-list-item"
            key={'item-' + map.modelId}
            onClick={() => onItemClick(map)}
        >{map.modelName}</div>
    });

    if (!activeMap) return null;

    return <div className="position-relative taxonomy-map-selector" ref={taxonomyMapContainer}>
        <div onClick={() => setListOpen(true)} className="d-flex">
            <span className="taxonomy-map-selector-label mr-2">Current Taxonomy View:</span>
            <span className="taxonomy-map-selector-name mr-2">{activeMap?.modelName}</span>
            <i className="fa fa-chevron-down"></i>
        </div>
        {listOpen &&
            <div className="taxonomy-map-selector-list">{mapsListItems}</div>
        }
    </div>

}