import { Observable } from 'rxjs';
import Axios from 'axios-observable';

const Domain = process.env.REACT_APP_MAIN_API_SERVER;

class Jurisdictions {
    get() {
        return new Observable(subscriber => {
            Axios.get(Domain + '/regulators/jurisdictions/').subscribe(resp => {
                const tree = this.prepareTree(resp.data.countries);
                subscriber.next(tree);
                subscriber.complete();
            });
        })
    }
    prepareTree(data) {
        const jurisdictions = [];
        const jurCodes = {};

        data.forEach(item => {
            item = { ...item, id: item.value, key: item.value, title: item.label };

            if (!item.region) jurisdictions.push(item);
            else {
                if (!jurCodes.hasOwnProperty(item.region)) jurCodes[item.region] = [];
                jurCodes[item.region].push(item);
            }
        });

        jurisdictions.forEach(item => {
            item.children = jurCodes[item.value];
        });
        return jurisdictions;
    }
}
export default new Jurisdictions();