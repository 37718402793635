import { Observable } from 'rxjs';
import Axios from 'axios-observable';

const Domain = process.env.REACT_APP_MAIN_API_SERVER;

class SavedSearches {
    get() {
        return new Observable(subscriber => {
            Axios.get(Domain + '/saved-search/').subscribe(resp => {
                subscriber.next(resp.data.saved_searches);
                subscriber.complete();
            });
        })
    }
}
export default new SavedSearches();