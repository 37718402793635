import React from 'react';
import AuthService from '@services/Auth/AuthService';
// import { Menu, Dropdown } from 'antd';
const { Button } = window['appkit-react'];

class GlobalSettings extends React.Component {
    render() {
        return <Button size="lg" kind="secondary" onClick={() => AuthService.msalLogoutUser()} style={{ marginTop: '10px' }}><i className="fa fa-power-off mr-2"></i> Logout</Button>
    }
}
export default GlobalSettings;