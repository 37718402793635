import { Observable } from 'rxjs';
import Axios from 'axios-observable';

const Domain = process.env.REACT_APP_WORKFLOW_API_SERVER;

class Workflows {
    get() {
        return new Observable(subscriber => {
            Axios.get(Domain + '/task-automation/workflows/').subscribe(resp => {
                subscriber.next(resp.data.workflows);
                subscriber.complete();
            });
        })
    }
}
export default new Workflows();