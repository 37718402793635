import { Observable, zip, Subject } from 'rxjs';

import Jurisdictions from './MicroServices/Jurisdictions';
import Regulators from './MicroServices/Regulators';
import DocumentTypes from './MicroServices/DocumentTypes';
import Languages from './MicroServices/Languages';
import ServiceLines from './MicroServices/ServiceLines';
import RegulatoryCategories from './MicroServices/RegulatoryCategories';
// import Themes from './MicroServices/Themes';
import ThemesFull from './MicroServices/ThemesFull';
import ClientTaxonomiesFull from './MicroServices/ClientTaxonomiesFull';
// import ClientTaxonomies from './MicroServices/ClientTaxonomies';
import Clients from './MicroServices/Clients';
import WorkflowUsers from './MicroServices/WorkflowUsers';
import UserGroups from './MicroServices/UserGroups';
import Workflows from './MicroServices/Workflows';
import RFclients from './MicroServices/RFclients';
import SavedSearches from './MicroServices/SavedSearches';
import Colleagues from './MicroServices/Colleagues';
import TaxonomyMaps from './MicroServices/TaxonomyMaps';

class CacheService {
    constructor() {
        this.services = {
            jurisdictions: null,
            regulators: null,
            documentTypes: null,
            languages: null,
            serviceLines: null,
            regulatoryCategories: null,
            themes: null,
            themesFull: null,
            clientTaxonomies: null,
            clientTaxonomiesFull: null,
            clients: null,
            workflowUsers: null,
            userGroups: null,
            workflows: null,
            rfClients: null,
            savedSearches: null,
            colleagues: null,
            taxonomyMaps: null
        }

        // Two lines below are created to avoid requesting long requests for themes and CT.
        // They are used to observe values when CT full and Themes full are loaded.
        this.$clientTaxonomies = new Subject();
        this.$themes = new Subject();
    }

    reset(type) {
        if (type) this.services[type] = null;
        else {
            for (let i in this.services) this.services[i] = null;
        }
    }

    getJurisdictions() {
        return new Observable(subscriber => {
            if (this.services.jurisdictions) {
                subscriber.next(this.services.jurisdictions);
                subscriber.complete();
            }
            else {
                Jurisdictions.get().subscribe(resp => {
                    this.services.jurisdictions = resp;
                    subscriber.next(this.services.jurisdictions);
                    subscriber.complete();
                });
            }
        });
    }

    getRegulators() {
        return new Observable(subscriber => {
            if (this.services.regulators) {
                subscriber.next(this.services.regulators);
                subscriber.complete();
            }
            else {
                Regulators.get().subscribe(resp => {
                    this.services.regulators = resp;
                    subscriber.next(this.services.regulators);
                    subscriber.complete();
                });
            }
        });
    }

    getDocumentTypes() {
        return new Observable(subscriber => {
            if (this.services.documentTypes) {
                subscriber.next(this.services.documentTypes);
                subscriber.complete();
            }
            else {
                DocumentTypes.get().subscribe(resp => {
                    this.services.documentTypes = resp;
                    subscriber.next(this.services.documentTypes);
                    subscriber.complete();
                });
            }
        });
    }

    getLanguages() {
        return new Observable(subscriber => {
            if (this.services.languages) {
                subscriber.next(this.services.languages);
                subscriber.complete();
            }
            else {
                Languages.get().subscribe(resp => {
                    this.services.languages = resp;
                    subscriber.next(this.services.languages);
                    subscriber.complete();
                });
            }
        });
    }

    getServiceLines() {
        return new Observable(subscriber => {
            if (this.services.serviceLines) {
                subscriber.next(this.services.serviceLines);
                subscriber.complete();
            }
            else {
                ServiceLines.get().subscribe(resp => {
                    this.services.serviceLines = resp;
                    subscriber.next(this.services.serviceLines);
                    subscriber.complete();
                });
            }
        });
    }

    getRegulatoryCategories() {
        return new Observable(subscriber => {
            if (this.services.regulatoryCategories) {
                subscriber.next(this.services.regulatoryCategories);
                subscriber.complete();
            }
            else {
                RegulatoryCategories.get().subscribe(resp => {
                    this.services.regulatoryCategories = resp;
                    subscriber.next(this.services.regulatoryCategories);
                    subscriber.complete();
                });
            }
        });
    }

    getThemes() {
        function flatThemesArray(items) {
            const themes = [];

            items.forEach(item => {
                themes.push({
                    id: item.taxonomy_id,
                    root_node: item.id,
                    value: item.taxonomy_id,
                    name: item.title,
                    title: item.title
                });
                if (item.children?.length) themes.push(...flatThemesArray(item.children));
            });
            return themes;
        }

        return new Observable(subscriber => {
            this.$themes.subscribe(resp => {
                const themes = flatThemesArray(resp);
                subscriber.next(themes);
                subscriber.complete();
            });
        });
    }

    getThemesFull() {
        return new Observable(subscriber => {
            if (this.services.themesFull) {
                this.$themes.next(this.services.themesFull);
                subscriber.next(this.services.themesFull);
                subscriber.complete();
            }
            else {
                ThemesFull.get().subscribe(resp => {
                    this.services.themesFull = resp;
                    this.$themes.next(resp);
                    subscriber.next(this.services.themesFull);
                    subscriber.complete();
                });
            }
        });
    }

    getClientTaxonomies() {
        function flatClientTaxonomiesArray(items) {
            const clientTaxonomies = [];

            items.forEach(item => {
                clientTaxonomies.push({
                    id: item.taxonomy_id,
                    root_node: item.id,
                    value: item.taxonomy_id,
                    name: item.title,
                    title: item.title
                });
            });
            return clientTaxonomies;
        }

        return new Observable(subscriber => {
            this.$clientTaxonomies.subscribe(resp => {
                const clientTaxonomies = flatClientTaxonomiesArray(resp);
                subscriber.next(clientTaxonomies);
                subscriber.complete();
            });
        });
    }

    getClientTaxonomiesFull() {
        return new Observable(subscriber => {
            if (this.services.clientTaxonomiesFull) {
                this.$clientTaxonomies.next(this.services.clientTaxonomiesFull);
                subscriber.next(this.services.clientTaxonomiesFull);
                subscriber.complete();
            }
            else {
                ClientTaxonomiesFull.get().subscribe(resp => {
                    this.services.clientTaxonomiesFull = resp;
                    this.$clientTaxonomies.next(resp);
                    subscriber.next(this.services.clientTaxonomiesFull);
                    subscriber.complete();
                });
            }
        });
    }

    getClients() {
        return new Observable(subscriber => {
            if (this.services.clients) {
                subscriber.next(this.services.clients);
                subscriber.complete();
            }
            else {
                Clients.get().subscribe(resp => {
                    this.services.clients = resp;
                    subscriber.next(this.services.clients);
                    subscriber.complete();
                });
            }
        });
    }

    getRFclients() {
        return new Observable(subscriber => {
            if (this.services.rfClients) {
                subscriber.next(this.services.rfClients);
                subscriber.complete();
            }
            else {
                RFclients.get().subscribe(resp => {
                    this.services.rfClients = resp;
                    subscriber.next(this.services.rfClients);
                    subscriber.complete();
                });
            }
        });
    }

    getWorkflowUsers() {
        return new Observable(subscriber => {
            if (this.services.workflowUsers) {
                subscriber.next(this.services.workflowUsers);
                subscriber.complete();
            }
            else {
                WorkflowUsers.get().subscribe(resp => {
                    this.services.workflowUsers = resp;
                    subscriber.next(this.services.workflowUsers);
                    subscriber.complete();
                });
            }
        });
    }

    getUserGroups() {
        return new Observable(subscriber => {
            if (this.services.userGroups) {
                subscriber.next(this.services.userGroups);
                subscriber.complete();
            }
            else {
                UserGroups.get().subscribe(resp => {
                    this.services.userGroups = resp;
                    subscriber.next(this.services.userGroups);
                    subscriber.complete();
                });
            }
        });
    }

    getWorkflows() {
        return new Observable(subscriber => {
            if (this.services.workflows) {
                subscriber.next(this.services.workflows);
                subscriber.complete();
            }
            else {
                Workflows.get().subscribe(resp => {
                    this.services.workflows = resp;
                    subscriber.next(this.services.workflows);
                    subscriber.complete();
                });
            }
        });
    }

    getSavedSearches() {
        return new Observable(subscriber => {
            if (this.services.savedSearches) {
                subscriber.next(this.services.savedSearches);
                subscriber.complete();
            }
            else {
                SavedSearches.get().subscribe(resp => {
                    this.services.savedSearches = resp;
                    subscriber.next(this.services.savedSearches);
                    subscriber.complete();
                });
            }
        });
    }

    getColleagues() {
        return new Observable(subscriber => {
            if (this.services.colleagues) {
                subscriber.next(this.services.colleagues);
                subscriber.complete();
            }
            else {
                Colleagues.get().subscribe(resp => {
                    this.services.colleagues = resp;
                    subscriber.next(this.services.colleagues);
                    subscriber.complete();
                });
            }
        });
    }

    getTaxonomyMaps() {
        return new Observable(subscriber => {
            if (this.services.taxonomyMaps) {
                subscriber.next(this.services.taxonomyMaps);
                subscriber.complete();
            }
            else {
                TaxonomyMaps.get().subscribe(resp => {
                    this.services.taxonomyMaps = resp;
                    subscriber.next(this.services.taxonomyMaps);
                    subscriber.complete();
                });
            }
        });
    }

    getAllServices() {
        return zip(
            this.getJurisdictions(),
            this.getRegulators(),
            this.getDocumentTypes(),
            this.getLanguages(),
            this.getServiceLines(),
            this.getRegulatoryCategories(),
            this.getThemes(),
            this.getThemesFull(),
            this.getClientTaxonomies(),
            this.getClientTaxonomiesFull(),
            this.getRFclients(),
            this.getUserGroups(),
            this.getSavedSearches(),
            this.getWorkflowUsers(),
            this.getColleagues(),
            this.getTaxonomyMaps()
        );
    }

    getAllCacheData() {
        return new Observable(subscriber => {
            this.getAllServices().subscribe(resp => {

                const cacheData = {
                    jurisdictions: resp[0],
                    regulators: resp[1],
                    documentTypes: resp[2],
                    languages: resp[3],
                    serviceLines: resp[4],
                    regCategories: resp[5],
                    themes: resp[6],
                    themesFull: resp[7],
                    clientTaxonomies: resp[8],
                    clientTaxonomiesFull: resp[9],
                    rfClients: resp[10],
                    userGroups: resp[11],
                    savedSearches: resp[12],
                    workflowUsers: resp[13],
                    colleagues: resp[14],
                    taxonomyMaps: resp[15]
                }
                subscriber.next(cacheData);
                subscriber.complete();
            }, error => console.log(error));
        });
    }
}

const cache = new CacheService();
export default cache;