import React, { lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
// import MenuService from '@services/Menu/MenuService';

/* Components List */

// Private views (for logged users)
const SearchHomePage = lazy(() => import('@views/private/Search/Search'));
const AlertingList = lazy(() => import('@views/private/Alerts/AlertingList'));
const AlertingCreateUpdate = lazy(() => import('@views/private/Alerts/AlertingCreateUpdate/AlertingCreateUpdate'));
const GlobalContent = lazy(() => import('@views/private/GlobalContent/GlobalContent'));
const RegulatoryAnalysis = lazy(() => import('@views/private/RegulatoryAnalysis/RegulatoryAnalysis'));
const ControlsAnalysis = lazy(() => import('@views/private/ControlsAnalysis/ControlsAnalysis'));
const RiskHeatmaps = lazy(() => import('@views/private/RiskHeatmaps/RiskHeatmaps'));
const RegulationProfiler = lazy(() => import('@views/private/RegulationProfiler/RegulationProfiler'));
const ManageSavedSearches = lazy(() => import('@views/private/ManageSavedSearches/ManageSavedSearches'));
const HeatmapsFinesList = lazy(() => import('@views/private/HeatmapsFinesList/HeatmapsFinesList'));
const ItemDetails = lazy(() => import('@views/private/ItemDetail'));
const Licences = lazy(() => import('@views/private/Licences/Licences'));

export const suspenseFallback = () => {
    return (
        <div className="wrapper">
            <div className="card">
                <div className="card-body">Loading...</div>
            </div>
        </div>
    )
}

export const Routes = () => (
    <Suspense fallback={suspenseFallback()}>
        <Switch>
            <Route path="/search" main="search" component={SearchHomePage} />
            <Route path="/alerts/update/:id" main="alerts" component={AlertingCreateUpdate} />
            <Route path="/alerts/create-from-search" main="alerts" component={AlertingCreateUpdate} />
            <Route path="/alerts/create" main="alerts" component={AlertingCreateUpdate} />
            <Route path="/alerts" main="alerts" component={AlertingList} />
            <Route path="/regulatory-analysis" main="regulatory-analysis" component={RegulatoryAnalysis} />
            <Route path="/global-content" main="global-content" component={GlobalContent} />
            <Route path="/controls-analysis" main="controls-analysis" component={ControlsAnalysis} />
            <Route path="/risk-heatmaps" main="risk-heatmaps" component={RiskHeatmaps} />
            <Route path="/regulation-profiler" main="regulation-profiler" component={RegulationProfiler} />
            <Route path="/manage-saved-searches" main="manage-saved-searches" component={ManageSavedSearches} />
            <Route path="/fines-list" main="risk-heatmaps" component={HeatmapsFinesList} />
            <Route path="/latest/detail" main="item-details" component={ItemDetails} />
            <Route path="/licenses" main="licences" component={Licences} />

            <Route path="*">
                <Redirect to="/search" />
            </Route>
        </Switch>
    </Suspense>
)