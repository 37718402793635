import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.copyrightDate = new Date().getFullYear();
    }
    render() {
        return (
            <footer className="app-footer" >
                <div className="row" style={{ width: '100%' }}>
                    <div className="col-10 py-2 position-relative" style={{ fontSize: '12px' }}>
                        Information provided via this site has been compiled using software powered by Corlytics and solely from data and information that is publicly available. This application is provided by Corlytics and used by PwC. PwC Solutions or PwC Legal are not responsible for any errors or omissions, or for the results obtained from the use of this information. PwC is responsible under data protection law for the data processing that takes place in the context of logging in via the PwC Engagement Center.
                        The Privacy Statement stored there applies to this data processing. For all other processing when using the application, the Privacy Statement of Corlytics applies. All information in this site is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information. Information on this site is for information purposes only and is not intended to be a substitute for legal advice.
                    </div>
                    <div className="col-2 text-right">
                        <Link style={{ position: 'absolute', right: '15px', bottom: '5px' }} to="/licenses">Licenses</Link>
                    </div>
                </div>
            </footer>
        )
    }
}
export default Footer;