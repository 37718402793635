import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import store from 'store2';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import PageBody from './PageBody';
import AuthService from '@services/Auth/AuthService';

import '@style/private.scss';

// Single Sign On Authentication
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
// import { EventType } from "@azure/msal-browser";
import { loginRequest } from '../../MsalConfig/authConfig';

const queryString = require('query-string');

function setRedirectUri(uri) {
    const qString = queryString.parse(uri);

    if (!qString.state) return;

    const parts = qString.state.split('|');
    if (!parts.length || parts.length < 2) return;

    const partsDict = JSON.parse(parts[1]);

    let url = '';

    for (let i in partsDict.params) {
        if (!i || !i.length) continue;

        url += (!url.length ? '?' : '&') + i + '=' + partsDict.params[i];
    }
    store.set('RedirectURL', partsDict.url + url);
}

export default function PrivateContainer() {
    const [initialSettings, setInitialSettings] = useState(false);
    const [initialSettingsRequest, setInitialSettingsRequest] = useState(false);

    const { instance } = useMsal();
    const { accounts } = useMsal();

    setRedirectUri(window.location.href);

    AuthService.setMsalData(instance, accounts);

    const userAccessSub = AuthService.userAccessInitial.subscribe(resp => {
        const roles = AuthService.getUserRoles();

        setInitialSettings(true);
    });


    useEffect(() => {
        // const callbackId = instance.addEventCallback((message) => {
        //     if (message.eventType === EventType.LOGIN_SUCCESS) {
        //         // const result = message.payload;
        //         // AuthService.setMsalToken(result.idToken);
        //         // AuthService.initService();
        //     }
        // });

        return () => {
            // if (callbackId) instance.removeEventCallback(callbackId);
            userAccessSub.unsubscribe();
        }
    });

    const msalLoginRedirect = () => {
        setTimeout(() => {
            instance.handleRedirectPromise().then(tokenResponse => {
                if (!tokenResponse) {
                    if (accounts.length === 0) instance.loginRedirect(loginRequest)
                }
            }).catch(error => {
                AuthService.msalLogoutUser();
            });
        }, 10);
        return null;
    }


    const initServices = () => {
        AuthService.initAxiosInterceptor();

        if (!initialSettings) {
            if (initialSettingsRequest) return;
            setInitialSettingsRequest(true);

            setTimeout(() => {
                AuthService.getUserInitialSettings().then(resp => {
                    AuthService.setUserInitialSettings(resp.data);
                }).catch(e => {
                    setInitialSettingsRequest(false);
                });
            }, 10);
        }

        const redirectUri = store.get('RedirectURL');

        if (redirectUri) {
            store.remove('RedirectURL');
            window.location.href = window.location.origin + redirectUri;
        }

        AuthService.toggleSpinner(false);
    }

    return <div className="app-container private">
        <Router basename={process.env.PUBLIC_URL}>
            <UnauthenticatedTemplate>
                {msalLoginRedirect()}
                {AuthService.toggleSpinner(true)}
                {/* <button className="btn btn-primary" onClick={() => instance.loginRedirect(loginRequest)}>Login</button> */}
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                {AuthService.isLoggedIn() && initServices()}
                {initialSettings &&
                    <>
                        <Header />
                        <Menu />
                        <PageBody />
                        <Footer />
                    </>
                }
            </AuthenticatedTemplate>
        </Router>
    </div>
}